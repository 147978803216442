import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InvoiceReceipt = _resolveComponent("InvoiceReceipt")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_InvoiceReceipt, {
      isNew: true,
      orderId: _ctx.orderId
    }, null, 8, ["orderId"])
  ]))
}